import React from 'react';
import cls from 'classnames';
import { useSelector } from 'react-redux';

import { useEnvironment } from '@wix/yoshi-flow-editor';
import settingsParams from 'Groups/settingsParams';

import { useSettings } from '@wix/tpa-settings/react';

import type { IGroup } from 'store/groups';
import { selectIsJoinedGroupMember } from 'store/selectors';

import { ListItem } from 'wui/ListItem';
import { ListItemText } from 'wui/ListItemText';
import { Skeleton } from 'wui/Skeleton';
import { Wire } from 'wui/Wire';
import { ListItemIcon } from 'wui/ListItemIcon';
import type { TPAComponentProps } from 'wui/types';

import { JoinedGroupListItem } from './JoinedGroupListItem';
import { SuggestedGroupListItem } from './SuggestedGroupListItem';

import classes from './GroupListItem.scss';

interface IGroupListItemProps extends TPAComponentProps {
  wired?: boolean;
  group: IGroup;
}

export function GroupListItem(props: IGroupListItemProps) {
  const { group } = props;

  const isJoined = useSelector(selectIsJoinedGroupMember(group.id as string));

  if (isJoined) {
    return <JoinedGroupListItem {...props} />;
  }

  return <SuggestedGroupListItem {...props} />;
}

GroupListItem.displayName = 'GroupListItem';

export function GroupListItemSkeleton(props: { wired?: boolean }) {
  const settings = useSettings();
  const { isMobile } = useEnvironment();

  return (
    <ListItem disablePadding className={cls({ [classes.wired]: props.wired })}>
      <Wire
        cssVarName="showImage--flex"
        legacyFallback={settings.get(settingsParams.showImage)}
      >
        <ListItemIcon>
          <Skeleton
            width={40}
            height={40}
            variant="rounded"
            className={classes.image}
          />
        </ListItemIcon>
      </Wire>
      <ListItemText
        title={
          <Skeleton
            variant="text"
            width="80%"
            className={cls(classes.title, { [classes.mobile]: isMobile })}
          />
        }
        subtitle={<Skeleton variant="text" width="45%" />}
      />
    </ListItem>
  );
}

GroupListItemSkeleton.displayName = 'GroupListItemSkeleton';
